"use client";
import ConsentManagerDialog from "components/consent-management/ConsentManagerDialog";
import { ProductCategory } from "models/ProductCategory.model";
import { env } from "process";
import {
	createContext,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from "react";
import {
	cdpClient,
	rudderDataPlaneUrl,
	rudderWriteKey,
} from "utils/analytics";
import { FRONTEND_URL } from "utils/config";

type PrivacyConsent = {
	analytics: boolean | null;
	personnalization: boolean | null;
	ads: boolean | null;
	essential: boolean | null;
};

// ============================================================
export type SettingsOptions = {
	fashion_preference: ProductCategory | null;
	recently_viewed_products_ids:
		| string[]
		| null;
	is_wishlist_enabled?: boolean;
	email_verification_sent_codes:
		| {
				email: string;
				timestamp: number;
		  }[]
		| null;
	checkout_choice?:
		| "guest"
		| "member"
		| null;
	privacy_consent: PrivacyConsent;
	has_clicked_on_live_chat?:
		| boolean
		| null;
};
// ============================================================

const initialSettings: SettingsOptions =
	{
		fashion_preference: null,
		recently_viewed_products_ids: null,
		is_wishlist_enabled:
			env.NEXT_PUBLIC_WISHLIST_ENABLED ===
			"true"
				? true
				: false,
		email_verification_sent_codes: null,
		checkout_choice: null,
		privacy_consent: {
			analytics: null,
			personnalization: null,
			ads: null,
			essential: null,
		},
		has_clicked_on_live_chat: null,
	};

export const SettingsContext =
	createContext({
		settings: initialSettings,
		updateSettings: (
			// eslint-disable-next-line
			arg: SettingsOptions
		) => {},
		retrievingSettings: true,
	});

// ============================================================
type settingsProviderProps = {
	children?: ReactNode;
};
// ============================================================

const SettingsProvider = ({
	children,
}: settingsProviderProps) => {
	const [
		retrievingSettings,
		setRetrievingSettings,
	] = useState(true);
	const [settings, setSettings] =
		useState(initialSettings);

	const updateSettings = (
		updatedSetting: SettingsOptions
	) => {
		setSettings(updatedSetting);
		window.localStorage.setItem(
			"bazaar_settings",
			JSON.stringify(updatedSetting)
		);
	};

	const isFirstRender = useRef(true);

	useEffect(() => {
		if (
			typeof window !== "undefined" &&
			isFirstRender.current
		) {
			const getItem =
				window.localStorage.getItem(
					"bazaar_settings"
				);
			if (getItem)
				setSettings(
					JSON.parse(getItem)
				);
			else {
				window.localStorage.setItem(
					"bazaar_settings",
					JSON.stringify(
						initialSettings
					)
				);
			}
		}
		setRetrievingSettings(false);
		isFirstRender.current = false;
	}, []);

	const [
		openConsentManagerDialog,
		setOpenConsentManagerDialog,
	] = useState(false);

	useEffect(() => {
		if (
			settings.privacy_consent
				?.essential === true ||
			retrievingSettings
		)
			return;
		setOpenConsentManagerDialog(true);
	}, [
		settings.privacy_consent?.essential,
		retrievingSettings,
	]);

	const hasLoadedAnalytics =
		useRef(false);

	useEffect(() => {
		if (hasLoadedAnalytics.current)
			return;

		if (retrievingSettings) return;

		const essentialConsent =
			settings?.privacy_consent
				?.essential;

		if (!essentialConsent) return;

		const analyticsConsent = Boolean(
			settings?.privacy_consent
				?.analytics
		);

		const disableClientPersistence =
			!analyticsConsent;

		if (disableClientPersistence) {
			cdpClient?.reset();
		}

		const personnalizationConsent =
			Boolean(
				settings?.privacy_consent
					?.personnalization
			);
		const adsConsent = Boolean(
			settings?.privacy_consent?.ads
		);

		const stagingOrProduction =
			FRONTEND_URL.includes(
				"https://www.noomidoo.com"
			)
				? "Production"
				: "Staging";

		const integrations = {
			["Posthog " +
			stagingOrProduction]:
				analyticsConsent,
			["Google Analytics " +
			stagingOrProduction]:
				analyticsConsent,
			["Algolia " +
			stagingOrProduction]:
				personnalizationConsent,
			["Facebook Pixel " +
			stagingOrProduction]:
				analyticsConsent,
			["Google Ads " +
			stagingOrProduction]: adsConsent,
			["TikTok Ads " +
			stagingOrProduction]: adsConsent,
		};

		cdpClient?.load(
			rudderWriteKey,
			rudderDataPlaneUrl,
			{
				storage: {
					type: disableClientPersistence
						? "none"
						: "cookieStorage",
				},
				integrations,
			}
		);
		hasLoadedAnalytics.current = true;
	}, [
		settings.privacy_consent?.analytics,
		settings.privacy_consent?.essential,
		settings.privacy_consent
			?.personnalization,
		settings.privacy_consent?.ads,
		retrievingSettings,
	]);

	return (
		<SettingsContext.Provider
			value={{
				settings,
				updateSettings,
				retrievingSettings,
			}}>
			<ConsentManagerDialog
				bannerOpen={
					openConsentManagerDialog
				}
				setBannerOpen={
					setOpenConsentManagerDialog
				}
			/>
			{children}
		</SettingsContext.Provider>
	);
};

export default SettingsProvider;
