import {
	Theme,
	useMediaQuery,
} from "@mui/material";

const useDeviceSize = () => {
	const upLg = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.up("lg")
	);
	const downLg = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.down("lg")
	);
	const downMd = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.down("md")
	);
	const upMd = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.up("md")
	);
	const upSm = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.up("sm")
	);
	const downSm = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.down("sm")
	);
	const downXs = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.down("xs")
	);
	const upXs = useMediaQuery(
		(theme: Theme) =>
			theme?.breakpoints?.up("xs")
	);

	return {
		upLg,
		downLg,
		downMd,
		upMd,
		downSm,
		upSm,
		downXs,
		upXs,
	};
};

export default useDeviceSize;
