export const layoutConstant = {
	topbarHeight: 40,
	headerHeight: 80,
	mobileNavHeight: 64,
	containerWidth: 1200,
	mobileHeaderHeight: 64,
	grocerySidenavWidth: 280,
	regularLayoutNavBarDisplay: {
		xs: "none",
		md: "block",
	},
	regularLayoutMobileExtraHeaderDisplay:
		{
			xs: "block",
			md: "none",
		},
};

export const IS_BROWSER =
	typeof window !== "undefined";
