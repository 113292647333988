import { FlexRowCenter } from "components/flex-box";
import CircularProgress from "@mui/material/CircularProgress";

interface ILoadingLayoutProps {
	minHeight?: string;
}

export default function LoadingLayout({
	minHeight = "100vh",
}: ILoadingLayoutProps) {
	return (
		<FlexRowCenter
			flexDirection='column'
			minHeight={minHeight}>
			<CircularProgress
				sx={{
					position: "absolute",
					top: "45%",
					transform:
						"translate(-50%, -50%)",
				}}
			/>
		</FlexRowCenter>
	);
}
