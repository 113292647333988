import { Link as MuiLink } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import BazaarCTA from "components/BazaarCta";
import {
	H3,
	Paragraph,
} from "components/Typography";
import { FlexBox } from "components/flex-box";
import useDeviceSize from "hooks/useDeviceSize";
import useSettings from "hooks/useSettings";
import Link from "next/link";
import * as React from "react";
import {
	LEGAL_COOKIES_NOTICE_PAGE_URL,
	LEGAL_PRIVACY_POLICY_PAGE_URL,
} from "utils/config";
// import { forceReloadPage } from "utils/forceReloadPage";
import ConsentPreferences from "./ConsentPreferences";

export interface ConsentManagerDialogProps {
	bannerOpen: boolean;
	setBannerOpen: React.Dispatch<
		React.SetStateAction<boolean>
	>;
}

const ConsentManagerDialog: React.FC<
	ConsentManagerDialogProps
> = ({ bannerOpen, setBannerOpen }) => {
	const { settings, updateSettings } =
		useSettings();

	const handleGlobalConsent = () => {
		updateSettings({
			...settings,
			privacy_consent: {
				analytics: true,
				personnalization: true,
				ads: true,
				essential: true,
			},
		});
		// forceReloadPage();
		setBannerOpen(false);
	};

	const handleEssentialConsent = () => {
		updateSettings({
			...settings,
			privacy_consent: {
				analytics: false,
				personnalization: false,
				ads: false,
				essential: true,
			},
		});
		// forceReloadPage();
		setBannerOpen(false);
	};

	const handleCustomConsent = () => {
		updateSettings({
			...settings,
			privacy_consent: {
				analytics: false,
				personnalization: false,
				ads: false,
				essential: true,
			},
		});
		toggleDrawer();
	};

	const { downSm: isMobile } =
		useDeviceSize();

	const [drawerOpen, setDrawerOpen] =
		React.useState(false);

	const toggleDrawer = () => {
		setDrawerOpen(!drawerOpen);
		setBannerOpen(!drawerOpen);
	};

	return (
		<>
			<TrapFocus
				open
				disableAutoFocus
				disableEnforceFocus>
				<Fade
					appear={false}
					in={bannerOpen}>
					<Paper
						aria-hidden={
							bannerOpen ? false : true
						}
						role='dialog'
						aria-modal='false'
						aria-label='Cookie banner'
						square
						variant='outlined'
						tabIndex={-1}
						sx={{
							bgcolor: (theme) =>
								theme?.palette?.black
									?.main,
							position: "fixed",
							bottom: 0,
							left: 0,
							right: 0,
							m: 0,
							p: 2,
							borderWidth: 0,
							borderTopWidth: 1,
							zIndex: (theme) =>
								theme.zIndex.modal +
								1000,
						}}>
						<Container maxWidth='lg'>
							<H3
								component='div'
								my={2}
								color={(theme) =>
									theme?.palette?.white
										?.main
								}>
								Nous vous proposons une
								expérience taillée sur
								mesure
							</H3>

							<Box
								sx={{
									maxHeight: isMobile
										? "25vh"
										: undefined,
									overflowY: "auto",
								}}>
								<Paragraph
									component='span'
									color={(theme) =>
										theme?.palette
											?.white?.main
									}>
									Nous utilisons des
									cookies et des outils
									similaires qui sont
									nécessaires pour vous
									permettre
									d&apos;effectuer des
									achats, pour améliorer
									vos expériences
									d&apos;achat et
									fournir nos services,
									comme détaillé dans
									notre &nbsp;
									<MuiLink
										// color={"white.main"}
										component={Link}
										href={
											LEGAL_COOKIES_NOTICE_PAGE_URL
										}
										target={"_blank"}
										rel={
											"noopener noreferrer"
										}>
										Avis sur les cookies
									</MuiLink>
									. Nous utilisons
									également ces cookies
									pour comprendre
									comment les clients
									qui utilisent nos
									services (par exemple,
									en mesurant les
									visites sur le site)
									afin que nous
									puissions apporter des
									améliorations.
								</Paragraph>
								<br />
								<br />
								<Paragraph
									component='span'
									color={(theme) =>
										theme?.palette
											?.white?.main
									}>
									Si vous acceptez en
									cliquant sur « OK »,
									nous utiliserons
									également des cookies
									complémentaires à
									votre expérience
									d&apos;achat, comme
									décrit dans notre Avis
									sur les cookies. Cela
									inclut
									l&apos;utilisation de
									cookies internes et
									tiers qui stockent ou
									accèdent aux
									informations standard
									de l&apos;appareil tel
									qu&apos;un identifiant
									unique. Les tiers
									utilisent des cookies
									dans le but
									d&apos;afficher et de
									mesurer des publicités
									personnalisées,
									générer des
									informations sur
									l&apos;audience, et
									développer et
									améliorer des
									produits. Cliquez sur
									«Uniquement les
									éssentiels» pour
									refuser ces cookies.
									Cliquez sur
									«Personnaliser les
									cookies» pour faire
									des choix plus
									détaillés ou en savoir
									plus. Vous pouvez
									modifier vos choix à
									tout moment en
									visitant Vos
									paramètres de cookies.
									Pour en savoir plus
									sur comment et à
									quelles fins nous
									utilisons les
									informations
									personnelles (tel que
									l&apos;historique des
									commandes de la
									boutique), consultez
									notre &nbsp;
									<MuiLink
										// color={"white.main"}
										component={Link}
										href={
											LEGAL_PRIVACY_POLICY_PAGE_URL
										}
										target={"_blank"}
										rel={
											"noopener noreferrer"
										}>
										Politique de
										confidentialité.
									</MuiLink>
								</Paragraph>
							</Box>

							<FlexBox
								flexDirection={{
									xs: "column-reverse",
									sm: "row",
								}}
								gap={3}
								my={3}>
								<BazaarCTA
									size='small'
									onClick={
										handleEssentialConsent
									}
									sx={{
										//  underline text
										textDecoration:
											"underline",
									}}>
									Uniquement les
									éssentielles
								</BazaarCTA>
								<BazaarCTA
									size='small'
									onClick={
										handleCustomConsent
									}
									sx={{
										//  underline text
										textDecoration:
											"underline",
									}}>
									Personnaliser les
									cookies
								</BazaarCTA>

								<BazaarCTA
									color='white'
									onClick={
										handleGlobalConsent
									}
									sx={{
										mr: 2,
									}}>
									OK
								</BazaarCTA>
							</FlexBox>
						</Container>
					</Paper>
				</Fade>
			</TrapFocus>
			<Drawer
				anchor='left'
				open={drawerOpen}
				onClose={toggleDrawer}
				aria-hidden={
					drawerOpen ? false : true
				}
				sx={{
					zIndex: (theme) =>
						theme.zIndex.modal +
						1000 +
						1,
				}}>
				<Container
					maxWidth='sm'
					sx={{ my: 4 }}>
					<ConsentPreferences
						toggleDrawer={toggleDrawer}
					/>
				</Container>
			</Drawer>
		</>
	);
};

export default ConsentManagerDialog;
