"use client";

import {
	Card,
	CardContent,
	CardHeader,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import MuiLink from "@mui/material/Link";
import BazaarCTA from "components/BazaarCta";
import {
	H1,
	H6,
	Paragraph,
} from "components/Typography";
import LoadingLayout from "components/layouts/LoadingLayout";
import useSettings from "hooks/useSettings";
import Link from "next/link";
import { enqueueSnackbar } from "notistack";
// import { useRouter } from "next/navigation";
import {
	FC,
	useEffect,
	useState,
} from "react";
import { LEGAL_COOKIES_NOTICE_PAGE_URL } from "utils/config";
type CheckboxSectionProps = {
	value: boolean;
	onChange: () => void;
	label: string;
	description: string;
	readOnly?: boolean;
};

const CheckboxSection: FC<
	CheckboxSectionProps
> = ({
	value,
	onChange,
	label,
	readOnly,
	description,
}) => {
	return (
		<>
			<Divider
				sx={{
					mt: 2,
					mb: 2,
				}}
			/>
			<FormControlLabel
				control={
					<Checkbox
						checked={value}
						onChange={() => onChange()}
						disabled={readOnly}
					/>
				}
				label={
					<H6 component='span'>
						{label}
					</H6>
				}
			/>
			<Paragraph
				component='span'
				my={2}>
				{description}
			</Paragraph>
		</>
	);
};

const ConsentPreferencesForm = ({
	toggleDrawer,
}: {
	toggleDrawer?: () => void;
}) => {
	// const router = useRouter();
	const { settings, updateSettings } =
		useSettings();

	const {
		privacy_consent: {
			analytics,
			personnalization,
			ads,
		},
	} = settings;

	const [formValues, setFormValues] =
		useState<{
			analytics: boolean | null;
			personnalization: boolean | null;
			ads: boolean | null;
			essential: boolean;
		}>({
			analytics,
			personnalization,
			ads,
			essential: true,
		});

	const handleCheckboxChange = (
		sectionName:
			| "analytics"
			| "personnalization"
			| "ads"
			| "essential",
		formValues: {
			analytics: boolean | null;
			personnalization: boolean | null;
			ads: boolean | null;
			essential: boolean;
		}
	) => {
		setFormValues({
			...formValues,
			[sectionName]: !formValues[
				sectionName
			] as boolean,
		});
	};

	const sideEffectsActions = () => {
		if (toggleDrawer) toggleDrawer();
		enqueueSnackbar(
			"Vos paramètres de confidentialité ont été mis à jour avec succès.",
			{
				variant: "success",
				persist: false,
				preventDuplicate: true,
				hideIconVariant: true,
				anchorOrigin: {
					vertical: "top",
					horizontal: "center",
				},
			}
		);
	};

	const handleAcceptAll = () => {
		updateSettings({
			...settings,
			privacy_consent: {
				analytics: true,
				personnalization: true,
				ads: true,
				essential: true,
			},
		});
		sideEffectsActions();
	};

	const handleFormSubmit = (e) => {
		e.preventDefault();
		updateSettings({
			...settings,
			privacy_consent: {
				...formValues,
			},
		});
		sideEffectsActions();
	};

	//  update formValues when settings change
	useEffect(() => {
		setFormValues({
			analytics,
			personnalization,
			ads,
			essential: true,
		});
	}, [
		settings,
		analytics,
		personnalization,
		ads,
	]);

	return (
		<Card>
			<CardHeader
				title={
					<H1>
						Paramètres de
						confidentialité
					</H1>
				}
				subheader={
					<Paragraph component='span'>
						Nous utilisons des cookies
						et des outils similaires qui
						sont nécessaires pour vous
						permettre d&apos;effectuer
						des achats, pour améliorer
						vos expériences d&apos;achat
						et fournir nos services,
						comme détaillé dans notre
						&nbsp;
						<MuiLink
							component={Link}
							href={
								LEGAL_COOKIES_NOTICE_PAGE_URL
							}
							target={"_blank"}
							rel={
								"noopener noreferrer"
							}>
							Avis sur les cookies
						</MuiLink>
						. Nous utilisons également
						ces cookies pour comprendre
						comment les clients qui
						utilisent nos services (par
						exemple, en mesurant les
						visites sur le site) afin
						que nous puissions apporter
						des améliorations.
					</Paragraph>
				}
			/>

			<CardContent>
				<BazaarCTA
					sx={{ my: 4 }}
					onClick={handleAcceptAll}>
					Tout accepter
				</BazaarCTA>

				<form
					onSubmit={handleFormSubmit}>
					<FormGroup>
						<CheckboxSection
							onChange={() =>
								handleCheckboxChange(
									"essential",
									formValues
								)
							}
							value={
								formValues.essential ??
								true
							}
							label='Nécissaires (Toujours actif)'
							description="Ces cookies ou outils similaires sont essentiels au bon fonctionnement du site. Ils vous permettent de naviguer et d’utiliser nos fonctionnalités comme « Ajouter au panier » ou « Enregistrer pour plus tard ». Ces cookies nous permettent de mesurer et d'analyser la manière dont nos clients utilisent le site, afin d'améliorer ses fonctionnalités et votre expérience d'achat."
							readOnly
						/>
						<CheckboxSection
							value={
								formValues.analytics ??
								false
							}
							onChange={() =>
								handleCheckboxChange(
									"analytics",
									formValues
								)
							}
							label='Analyses du site'
							description="Ces cookies ou outils similaires nous permettent de mesurer et d'analyser la manière dont nos clients utilisent le site, afin d'améliorer ses fonctionnalités et votre expérience d'achat."
						/>
						<CheckboxSection
							onChange={() =>
								handleCheckboxChange(
									"personnalization",
									formValues
								)
							}
							value={
								formValues.personnalization ??
								false
							}
							label='Personnalisation'
							description='Ces cookies ou outils similaires sont utilisés pour vous proposer des fonctionnalités optimisées lorsque vous utilisez notre site, comme par exemple des recommandations de produits, des résultats de recherche personnalisés.'
						/>
						<CheckboxSection
							onChange={() =>
								handleCheckboxChange(
									"ads",
									formValues
								)
							}
							value={
								formValues.ads ?? false
							}
							label='Publicité ciblée'
							description="Ces cookies ou outils similaires sont utilisés pour diffuser des annonces qui vous intéressent. Ils limitent également le nombre de fois où vous pouvez voir une publicité et nous aident à mesurer l'efficacité de nos campagnes marketing."
						/>
					</FormGroup>
					<BazaarCTA
						sx={{
							my: 4,
						}}
						type='submit'>
						Enregistrer la sélection des
						paramètres
					</BazaarCTA>
				</form>
			</CardContent>
		</Card>
	);
};

const ConsentPreferences = ({
	toggleDrawer,
}: {
	toggleDrawer?: () => void;
}) => {
	const { retrievingSettings } =
		useSettings();

	if (retrievingSettings) {
		return <LoadingLayout />;
	}

	return (
		<ConsentPreferencesForm
			toggleDrawer={toggleDrawer}
		/>
	);
};

export default ConsentPreferences;
