import { IS_BROWSER } from "./constants";

import { RudderAnalytics } from "@rudderstack/analytics-js/bundled";

export const rudderWriteKey =
	process.env
		.NEXT_PUBLIC_RUDDER_WRITE_KEY ??
	"test_key";
export const rudderDataPlaneUrl =
	process.env
		.NEXT_PUBLIC_RUDDER_DATA_PLANE_URL ??
	"test_url";

export const cdpClient = IS_BROWSER
	? new RudderAnalytics()
	: undefined;
